import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { BaseComponent, BreadcrumbComponent, HasPermissionDirective } from '@components';
import * as icons from '@fortawesome/free-solid-svg-icons';
import DashboardManager from '../../application/dashboard/dashboard.manager';
import { select } from '@ngneat/elf';
import { AuthenticationManager, permissionType } from '@application';
import { map, skipWhile, takeUntil } from 'rxjs';
import { Router } from '@angular/router';
import { searchOrthopErrorParams } from '../../domain/dashboard/types/dashboard-manager.types';



@Component({
	selector: 'intranet-home',
	standalone: true,
	imports: [
		CommonModule,
		FaIconComponent,
		BreadcrumbComponent,
		HasPermissionDirective
	],
	templateUrl: './home.component.html',
	styleUrl: './home.component.scss',
})
export class HomeComponent extends BaseComponent implements OnInit {

	icons = icons;
	public nbOrthopErrors: number | null = null;
	public permissionTypes = permissionType;


	constructor(
		private orthopManager: DashboardManager,
		private authenticatedManager: AuthenticationManager,
		private router: Router
	) {
		super();
	}

	ngOnInit() {
		this.subscribeAgency();
		this.subscribeOrthopErrors()

	}

	// RETRIEVE THE ORHTOP ERRORS
	private subscribeOrthopErrors() {
		this.orthopManager.store.pipe(
			select(state => state.orthopErrors),
			skipWhile(errors => errors.data == null),
		).subscribe(errors => {
			this.nbOrthopErrors = errors.pagination.total;
		});
	}

	// RETRIEVE THE CURRENT AGENCY ID
	private subscribeAgency() {
		this.authenticatedManager.store
			.pipe(
				skipWhile(agency => agency == null),
				takeUntil(this.$unsubscribe),
				select(state => state.currentUserAgency),
				map(agency => {
					this.retrieveOrthopErrors(agency?.id!)
				}),
			)
			.subscribe();
	}

	// PERFOEM THE SERVER CALL TO RETRIEVE ROTHOP ERRORS
	private retrieveOrthopErrors(agencyId: number) {
		let params: searchOrthopErrorParams = {
			agencyId: agencyId,
			pageNumber: 1,
			pageSize: 10
		}

		this.orthopManager.retrieveOrthopErrors(params);
	}

	public goOrhtopErrors() {
		this.router.navigate(["dashboard/orthop"])
	}


}
