<div class="application-container">

	<div class="navigation-container">
		@if (authenticationManager.store.state.access) {
			<intranet-navigation></intranet-navigation>
		}
	</div>

	<main class="main-container">
		<router-outlet></router-outlet>
	</main>

</div>
