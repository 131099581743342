<h3>Accueil</h3>
<div class="flex">


	<!-- ORTHOP CARD -->
	<div class="card hover mt-large" (click)="goOrhtopErrors()"
		[hasPermission]="{ permissionSlug: permissionTypes.ORTHOP_CARD, permissionType: 'read' }">
		<p class="card-header">Erreur@if( nbOrthopErrors! > 1){<span>s</span>}
			Orthop
		</p>

		<div class="flex">
			@if(nbOrthopErrors != null ){
			<p class="card-main-info align-self-center">{{ nbOrthopErrors }}</p>
			}
			@else{
			<div class="spinner-small align-self-center"></div>
			}
			<fa-icon size="4x" class="warning-icon ml-auto" [icon]="icons.faExclamationTriangle"></fa-icon>

		</div>
		<p class="card-sub-info mt-small">Nombre d'erreurs lors de la synchronisation avec Orthop</p>
	</div>
</div>