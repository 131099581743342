import { Route } from '@angular/router';
import { UserDetailsComponent } from './pages/user-management/user-details/user-details.component';
import { AccessDeniedComponent } from './pages/access-denied/access-denied.component';
import { ModuleGuard } from '@application';
import { HomeComponent } from './pages/home/home.component';

export const appRoutes: Route[] = [
	{
		path: '',
		redirectTo: 'home',
		pathMatch: 'full',
	},
	{
		path: 'home',
		component: HomeComponent,
		data: {
			slug: 'TableauDeBord',
		},
		canActivate: [ModuleGuard],
	},
	{
		path: 'dashboard',
		loadChildren: () => import('./pages/dashboards/dashboard-route').then(r => r.dashboardRoute),
		data: {
			slug: '',
		},
		canActivate: [ModuleGuard],
	},
	{
		path: 'patient',
		loadChildren: () => import('./pages/patient/patient-route').then(r => r.patientRoute),
		data: {
			slug: 'Patients',
		},
		canActivate: [ModuleGuard],
	},
	{
		path: 'doctors',
		loadChildren: () => import('./pages/doctor/doctor-route').then(r => r.doctorRoute),
		data: {
			slug: 'Docteurs',
		},
		canActivate: [ModuleGuard],
	},
	{
		path: 'stocks',
		loadChildren: () => import('./pages/stocks/stock-route').then(r => r.stockRoute),
		data: {
			slug: 'GestionDesStocks',
		},
		canActivate: [ModuleGuard],
	},

	{
		path: 'planning-tech',
		loadChildren: () => import('./pages/planning-tech/planning-tech-route').then(r => r.planningTechRoute),
		data: {
			slug: 'PlanningTechniciens',
		},
		canActivate: [ModuleGuard],
	},
	{
		path: 'parametres',
		component: UserDetailsComponent,
		data: {
			slug: '',
			breadcrumb: {
				labels: ['Paramètres'],
				path: '',
			},
		},
		canActivate: [ModuleGuard],
	},
	{
		path: 'user',
		data: {
			slug: 'AccesUtilisateur',
		},
		loadChildren: () => import('./pages/user-management/user-management-route').then(r => r.userManagementRoutes),
		canActivate: [ModuleGuard],
	},
	{
		path: 'documents-techniciens',
		loadChildren: () => import('./pages/documents-techniciens/documents-techniciens-route').then(r => r.documentsTechniciensRoute),
		canActivate: [ModuleGuard],
		data: {
			slug: 'DocumentsTechniciens',
		},
	},
	{
		path: 'access-denied',
		component: AccessDeniedComponent,
	},
];
